var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',{staticClass:"stacked-form",attrs:{"title":"General Program Information"}},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-5"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('base-input',{class:[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ],attrs:{"required":"","label":"Program Name","type":"text","placeholder":"Enter program name","error":errors[0]},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}])}),_c('label',{attrs:{"for":"Start Date"}},[_vm._v("Program Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.description),expression:"model.description"}],staticClass:"form-text form-control",attrs:{"placeholder":"Enter program description","label":"Description","name":"description","id":"","cols":"30","rows":"10"},domProps:{"value":(_vm.model.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.model, "description", $event.target.value)}}})],1),_c('div',{staticClass:"col-sm-5"},[_c('ValidationProvider',{attrs:{"name":"Start Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('label',{attrs:{"for":"Start Date"}},[_vm._v("Program Start Date")]),_c('el-date-picker',{class:[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ],attrs:{"required":"","type":"date","placeholder":"Start Date","error":errors[0]},model:{value:(_vm.model.start),callback:function ($$v) {_vm.$set(_vm.model, "start", $$v)},expression:"model.start"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('label',{staticClass:"mt-4",attrs:{"for":"End Date"}},[_vm._v("Program End Date")]),_c('el-date-picker',{class:[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ],attrs:{"required":"","type":"date","label":"End Date","placeholder":"End Date","error":errors[0]},model:{value:(_vm.model.end),callback:function ($$v) {_vm.$set(_vm.model, "end", $$v)},expression:"model.end"}})]}}])})],1)])])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }