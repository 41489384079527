<template>
  <div>
    <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
    <div v-if="!isLoading" class="row d-flex justify-content-center">
      <div class="col-md-12">
        <simple-wizard finishButtonText="Create Program">
          <template slot="header">
            <h3 class="card-title">Create a new program</h3>
            <h3 class="description">
              Follow the steps to create and configure your program.
            </h3>
          </template>
          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="lni lni-bricks"></i>
              <p>General Information</p>
            </template>
            <general-info-step
              ref="step1"
              @on-validated="onStepValidated"
            ></general-info-step>
          </wizard-tab>
          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-chart-bar-32"></i>
              <p>Data Points</p>
            </template>
            <data-points-step
              ref="step2"
              @on-validated="onFinalize"
            ></data-points-step>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import { SimpleWizard, WizardTab } from "src/components";
import swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";
import DataPointsStep from "./Steps/DataPointsStep.vue";
import GeneralInfoStep from "./Steps/GeneralInfoStep.vue";
export default {
  data() {
    return {
      isLoading: false,
      model: {
        name: "",
        description: "",
        start: "",
        end: "",
        additionalQuestions: [],
        performanceDataPoints: [],
        sectors: [],
        stages: [],
      },
    };
  },
  components: {
    GeneralInfoStep,
    DataPointsStep,
    SimpleWizard,
    WizardTab,
  },
  methods: {
    ...mapGetters({
      authUser: "auth/authUser",
    }),
    ...mapActions({
      createProgram: "program/createProgram",
    }),
    generateprogramID(programName) {
      let id = Math.random().toString(36).substring(2, 8);
      id = programName.slice(0, 2).toUpperCase() + "-" + id;
      return id;
    },
    async submit() {
      this.isLoading = true;
      let router = this.$router;
      // 
      try {
        let program = {
          time: Number(new Date().valueOf()),
          id: this.generateprogramID(this.model.name),
          adminId: this.authUser().id,
          ...this.model,
        };
        console.warn("creating Program");
        await this.createProgram(program);
        this.isLoading = false;
        swal.fire({
          title: `Success!`,
          text: "You have succesfully created a program",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "success",
        });
        router.push("/programs");
      } catch (error) {
        this.isLoading = false;
        swal.fire({
          title: `Program Creation Failed!`,
          text: error.message,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "error",
        });
      }
    },
    createPerformanceData(performanceDataPoints) {
      // TODO create historical performance data point maps when startup is added to a program.
    },
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      if (validated) {
        this.model = { ...this.model, ...model };
      }
    },
    onFinalize(validated, model) {
      if (validated) {
        this.model = { ...this.model, ...model };
        // 
        this.submit();
      }
    },
  },
};
</script>
