<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="General Startup Information">
        <ValidationObserver ref="form">
          <form @submit.prevent="validate">
            <div class="row justify-content-between">
              <div class="col-sm-4">
                <h6>About Startup</h6>
                <ValidationProvider name="Name" rules="required|min:3" v-slot="{ passed, failed, errors }">
                  <base-input class="mt-4" required label="Startup Name" v-model="model.name" type="text"
                    placeholder="Enter Startup name" :error="errors[0]" :class="[
            { 'has-success': passed },
            { 'has-danger': failed },
          ]">
                  </base-input>
                </ValidationProvider>
                <ValidationProvider name="Description" v-slot="{ passed, failed, errors }" rules="required">
                  <div class="d-flex flex-column pt-2">
                    <base-input class="mb-0" required label="Startup Description" v-model="model.description"
                      type="text" isTextArea placeholder="Short description about the startup" name="Description"
                      :error="errors[0]" :class="[
            { 'has-success': passed },
            { 'has-danger': failed },
          ]">
                    </base-input>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="Business Registration Date" v-slot="{ passed, failed, errors }">
                  <label class="pt-2" for="Business Registration Date">Business Registration Date</label>
                  <el-date-picker type="date" placeholder="Business Registration Date" :pickerOptions="pickerOptions"
                    v-model="model.businessRegistrationDate" :error="errors[0]" :class="[
            { 'has-success': passed },
            { 'has-danger': failed },
          ]">
                  </el-date-picker>
                  <label class="text-danger">{{ errors[0] }}</label>
                </ValidationProvider>
                <div :v-if="additionalQuestions">
                  <ValidationProvider :name="question.question" rules="required|min:3"
                    v-slot="{ passed, failed, errors }" v-for="question in additionalQuestions" :key="question.id">
                    <base-input class="mt-4" required :label="question.question"
                      v-model="model.additionalQuestionsWithAnswers[question.id]" type="text" isTextArea
                      :placeholder="question.question" :error="errors[0]" :class="[
            { 'has-success': passed },
            { 'has-danger': failed },
          ]">
                    </base-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-3">
                <h6>Startup Stats</h6>
                <ValidationProvider name="Startup Sector" rules="required" v-slot="{ errors }">
                  <div class="d-flex flex-column pt-4">
                    <label for="Sector">Startup Sector</label>
                    <el-select class="select-default" size="large" label="Startup Sector"
                      placeholder="Select Startup Sector" v-model="model.sector" :error="errors[0]">
                      <el-option v-for="option in programSectors" class="select-danger" :value="option" :label="option"
                        :key="option">
                      </el-option>
                    </el-select>
                    <label class="text-danger">{{ errors[0] }}</label>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="Startup Stage" rules="required" v-slot="{ errors }">
                  <div class="d-flex flex-column pt-2">
                    <label for="Startup Stage">Startup Stage</label>
                    <el-select class="select-default" size="large" label="Startup Sector"
                      placeholder="Select Startup Stage" v-model="model.stage" :error="errors[0]">
                      <el-option v-for="option in programStages" class="select-danger" :value="option" :label="option"
                        :key="option">
                      </el-option>
                    </el-select>
                    <label class="text-danger">{{ errors[0] }}</label>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="Annual Revenue" rules="required|numeric|integer"
                  v-slot="{ passed, failed, errors }">
                  <base-input class="mt-2" required label="Annual Revenue" v-model="model.annualRevenue"
                    placeholder="Enter Annual Revenue" :error="errors[0]" :class="[
            { 'has-success': passed },
            { 'has-danger': failed },
          ]">
                  </base-input>
                </ValidationProvider>
                <div class="col p-0">
                  <label for="" class="pt-2">Employees</label>
                  <div class="col p-0">
                    <div class="col-12 p-0">
                      <ValidationProvider name="Male" rules="required|numeric|regex:^\d+$"
                        v-slot="{ passed, failed, errors }">
                        <base-input addon-left-icon="fa fa-male" class="mt-2" required label="Male"
                        type="number"
                          v-model="model.employees.male" placeholder="0" :error="errors[0]" :class="[
            { 'has-success': passed },
            { 'has-danger': failed },
          ]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-12 p-0">
                      <ValidationProvider name="Female" rules="required|numeric|regex:^\d+$"
                        v-slot="{ passed, failed, errors }">
                        <base-input addon-left-icon="fa fa-female" class="mt-2" required label="Female"
                        type="number"
                          v-model="model.employees.female" placeholder="0" :error="errors[0]" :class="[
            { 'has-success': passed },
            { 'has-danger': failed },
          ]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Contact Info -->
              <div class="col-sm-4">
                <h6>Contact Information</h6>
                <ValidationProvider name="Phone" rules="required|numeric" v-slot="{ passed, failed, errors }">
                  <base-input label="Phone Number" required v-model="model.contactInfo.phone" placeholder="Phone"
                    type="tel" addon-left-icon="tim-icons icon-mobile" :error="errors[0]" :class="[
            { 'has-success': passed },
            { 'has-danger': failed },
          ]">
                  </base-input>
                </ValidationProvider>
                <ValidationProvider name="Email Address" rules="required|email" v-slot="{ passed, failed, errors }">
                  <base-input required label="Email Address" v-model="model.contactInfo.email" placeholder="Email"
                    addon-left-icon="tim-icons icon-email-85" :error="errors[0]" :class="[
            { 'has-success': passed },
            { 'has-danger': failed },
          ]">
                  </base-input>
                </ValidationProvider>
                <ValidationProvider name="City" rules="required" v-slot="{ passed, failed, errors }">
                  <base-input required label="City" v-model="model.contactInfo.city" placeholder="Addis Ababa"
                    addon-left-icon="tim-icons icon-square-pin" :error="errors[0]" :class="[
            { 'has-success': passed },
            { 'has-danger': failed },
          ]">
                  </base-input>
                </ValidationProvider>
                <ValidationProvider name="Address" rules="required" v-slot="{ passed, failed, errors }">
                  <base-input label="Address" required v-model="model.contactInfo.address"
                    placeholder="Yeka Subcity Wereda 12" addon-left-icon="tim-icons icon-square-pin" :error="errors[0]"
                    :class="[
            { 'has-success': passed },
            { 'has-danger': failed },
          ]">
                  </base-input>
                </ValidationProvider>
                <ValidationProvider name="Social Media Links" v-slot="{ errors }">
                  <label>Social Media Links (Optional)</label>
                  <tags-input class="ml-0" placeHolder="https://twitter.com/example"
                    v-model="model.socialMediaLinks"></tags-input>
                  <label class="my-2 text-danger">{{ errors[0] }}</label>
                  <br />
                </ValidationProvider>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import { DatePicker, Option, Select } from "element-ui";
import { TagsInput } from "src/components/index";
import { extend } from "vee-validate";
import { email, integer, min, numeric, regex, required } from "vee-validate/dist/rules";
import { mapActions } from "vuex";
extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);
extend("regex", regex);
extend("integer", integer);
export default {
  props: {
    additionalQuestions: {
      type: Array,
      description: "Additional Questions From Program",
    },
    programSectors: {
      type: Array,
      description: 'Sectors supported by program'
    },
    programStages: {
      type: Array,
      description: 'Stages for startups'
    }
  },
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    TagsInput,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      model: {
        name: null,
        businessRegistrationDate: null,
        description: null,
        contactInfo: {
          phone: null,
          email: null,
          address: null,
          city: null,
        },
        additionalQuestionsWithAnswers: {},
        socialMediaLinks: [],
        sector: null,
        stage: null,
        annualRevenue: null,
        employees: {
          male: 0,
          female: 0,
        },
      },
    };
  },
  methods: {
    ...mapActions({
      createUser: "auth/createUserAuth",
      addUser: "user/addUser",
    }),
    validate() {
      return this.$refs.form.validate().then((res) => {
        this.model.name = this.model.name.replace(/[^\w\s]/gi, "").trim();
        this.$emit("on-validated", res, this.model);
        return res;
      });
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
