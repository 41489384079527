<template>
  <div class="row">
    <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
    <div v-if="!isLoading" class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Edit Program Milestone</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <div>
              <div class="col-md-8">
                <ValidationProvider
                  name="Name"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    required
                    label="Name"
                    v-model="model.name"
                    type="text"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </base-input>
                </ValidationProvider>
              </div>
              <div class="col-md-8">
                <ValidationProvider
                  name="End Date"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                  <el-date-picker
                    required
                    type="date"
                    label="End Date"
                    placeholder="End Date"
                    v-model="model.workdays"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </el-date-picker>
                </ValidationProvider>
              </div>
              <br />
              <div class="col-md-8">
                <tags-input
                  v-if="model.tags != null"
                  label="Tags"
                  v-model="model.tags"
                ></tags-input>
              </div>
              <div class="col-md-8">
                <textarea
                  class="form-text form-control"
                  v-model="model.description"
                  placeholder="Enter program description"
                  label="Description"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
                <br />
              </div>
              <div class="col-md-8 d-flex justify-content-between">
                <base-button class="mt-4" id="submit" native-type="submit"
                  >Save</base-button
                >
                <base-button
                  class="mt-4"
                  @click.native="deleteItem()"
                  type="danger"
                  >Delete</base-button
                >
              </div>
            </div>
          </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import { DatePicker, Option, Select } from "element-ui";
// import firebase from 'firebase';
import { TagsInput } from "src/components/index";
import swal from "sweetalert2";
import { extend } from "vee-validate";
import { email, min, numeric, required } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    TagsInput,
  },
  data() {
    return {
      isLoading: true,
      model: {
        name: null,
        description: null,
        workdays: null,
        tags: null,
      },
    };
  },
  async created() {
    const milestones = await this.getProgramMilestones(
      this.$route.params.program_id
    );
    const milestone = milestones.filter(
      (milestone) => milestone.id == this.$route.params.milestone_id
    );
    this.model = milestone[0];
    this.model.workdays != null
      ? (this.model.workdays = this.model.workdays.toDate())
      : null;
    this.model.tags == null ? (this.model.tags = []) : null;
    this.isLoading = false;
  },
  computed: {
    ...mapGetters({
      getProgramMilestones: "program/getProgramMilestones",
    }),
  },
  methods: {
    ...mapActions({
      editProgramMilestone: "program/editProgramMilestone",
      deleteProgramMilestone: "program/deleteProgramMilestone",
    }),
    async submit() {
      try {
        document.getElementById("submit").disabled = true;
        const router = this.$router;
        const route = this.$route;
        const payload = {
          programId: route.params.program_id,
          milestoneId: route.params.milestone_id,
          milestone: this.model,
        };
        await this.editProgramMilestone(payload);
        swal.fire({
          title: `Success!`,
          text: "Changes to milestone saved",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "success",
        });
        document.getElementById("submit").disabled = true;
        router.go(-1);
      } catch (error) {
        document.getElementById("submit").disabled = true;
        swal.fire({
          title: `Editing Milestone Failed!`,
          text: error.message,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "error",
        });
      }
    },
    async deleteItem() {
      let confirmation = await swal.fire({
        title: "Are you sure?",
        text: `Please confirm you want to delete the milestone ${this.model.name}. You can't revert once you have deleted the item.`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-danger mr-2",
          cancelButton: "btn btn-default btn-fill",
        },
        confirmButtonText: "Delete Milestone",
        buttonsStyling: false,
      });
      if (confirmation.value) {
        try {
          const router = this.$router;
          const route = this.$route;
          const payload = {
            programId: route.params.program_id,
            milestoneId: route.params.milestone_id,
          };
          await this.deleteProgramMilestone(payload);
          swal.fire({
            title: `Success!`,
            text: "You have succesfully deleted this item",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-danger btn-fill",
            },
            icon: "success",
          });
          router.push(`/programs/detail/${payload.programId}`);
        } catch (error) {
          swal.fire({
            title: `Deleting Milestone Failed!`,
            text: error.message,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-danger btn-fill",
            },
            icon: "error",
          });
        }
      }
    },
  },
};
</script>
<style>
</style>
