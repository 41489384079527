<template>
  <div>
    <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
    <div v-if="!isLoading" class="row d-flex justify-content-center">
      <div class="col-md-12">
        <simple-wizard finishButtonText="Register Startup">
          <template slot="header">
            <h3 class="card-title">Register Startup</h3>
            <h3 class="description">
              Follow the steps to register your startup to
              {{ program.name }} Program.
            </h3>
          </template>
          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="lni lni-bricks"></i>
              <p>General Information</p>
            </template>
            <general-info-step
              ref="step1"
              @on-validated="onFinalize"
              :additional-questions="additionalQuestionsForProgram"
              :program-stages="program.stages"
              :program-sectors="program.sectors"
            ></general-info-step>
          </wizard-tab>
          <!-- <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-chart-bar-32"></i>
              <p>Data Points</p>
            </template>
            <data-points-step
              ref="step2"
              @on-validated="onStepValidated"
              :performance-data-points="performanceDataPointsForProgram"
            ></data-points-step>
          </wizard-tab> -->
          <!-- 
          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-single-02"></i>
              <p>Management</p>
            </template>
            <management-step
              ref="step3"
              @on-validated="onFinalize"
            ></management-step>
          </wizard-tab> -->
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import { SimpleWizard, WizardTab } from "src/components";
import swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";
import GeneralInfoStep from "./Steps/GeneralInfoStep.vue";
export default {
  data() {
    return {
      isLoading: false,
      programId: "",
      program: "",
      additionalQuestionsForProgram: [],
      performanceDataPointsForProgram: [],
      credentials: {
        email: null,
        password: null,
      },
      model: {
        name: null,
        id: null,
        businessRegistrationDate: null,
        description: null,
        contactInfo: {
          phone: null,
          email: null,
          address: null,
          city: null,
        },
        additionalQuestionsWithAnswers: {},
        socialMediaLinks: [],
        sector: null,
        stage: null,
        annualRevenue: null,
        employees: {
          male: null,
          female: null,
        },
      },
    };
  },
  components: {
    GeneralInfoStep,
    SimpleWizard,
    WizardTab,
  },
  created() {
    this.isLoading = true;
    this.programId = this.$route.params.program_id;
    this.getProgram(this.programId).then((program) => {
      this.program = program;
      this.additionalQuestionsForProgram = program.additionalQuestions;
      this.performanceDataPointsForProgram = program.performanceDataPoints;
      this.isLoading = false;
    });
  },
  mounted() {},
  computed: {
    ...mapGetters({
      type: "auth/type",
    }),
  },
  methods: {
    ...mapActions({
      addStartup: "startup/addStartup",
      getProgram: "program/getProgram",
      getCurrentUser: "user/getLoggedInUserData",
    }),
    generateStartUpId(startupName) {
      let id = Math.random().toString(36).substring(2, 8);
      id = startupName.slice(0, 2).toUpperCase() + "-" + id;
      return id;
    },
    async submit() {
      // document.getElementById("submit").disabled = true;
      try {
        this.isLoading = true;
        let router = this.$router;
        let startup = this.model;
        const currentUser = await this.getCurrentUser();
        if (currentUser == null) {
          throw { message: "User not found" };
        }
        let payload = {
          startup: startup,
          programId: this.programId,
          credentialEmail: currentUser.email,
        };
        // if (this.credentials.email == null || this.credentials.email == "") {
        //   throw "User email can't be empty";
        // }
        await this.addStartup(payload);
        this.isLoading = false;
        swal.fire({
          title: `Success!`,
          text: "You have succesfully added the startup",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "success",
        });
        router.push(`/programs/detail/${this.programId}`);
      } catch (error) {
        swal.fire({
          title: `Startup Creation Failed!`,
          text: error.message,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "error",
        });
        this.isLoading = false;
      }
    },
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      if (validated) {
      }
    },
    onFinalize(validated, model) {
      if (validated) {
        this.model = { ...this.model, ...model };
        this.model.id = this.generateStartUpId(this.model.name);
        this.submit();
      }
    },
  },
};
</script>
