<template>
  <div>
    <admin-dashboard v-if="type < 4"></admin-dashboard>
    <!-- <program-dashboard v-if="type == 2"></program-dashboard>
    <partner-dashboard v-if="type == 3"></partner-dashboard> -->
    <startup-detail v-if="type == 4"></startup-detail>
  </div>
</template>
<script>
import AdminDashboard from "./adminDashboard.vue";
// import ProgramDashboard from "./programDashboard.vue";
// import PartnerDashboard from "./partnerDashboard.vue";
import { mapGetters } from "vuex";
import StartupDetail from "../Ice/Startups/startupDetail.vue";
export default {
  components: {
    AdminDashboard,
    // ProgramDashboard,
    // PartnerDashboard,
    StartupDetail
  },
  computed: {
    ...mapGetters({type: 'auth/type'}),
  }
};
</script>
<style lang="scss">
</style>
