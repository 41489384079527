<template>
  <div class="row">
    <div class="col-md-12">
      <card
        class="stacked-form"
        title="Startup Data Points"
        subTitle="The data points created here will be used to measure each startup in the program"
      >
        <ValidationObserver ref="form">
          <form @submit.prevent="validate">
            <div class="row">
              <div class="col-12 col-md-6">
                <h6 class="mt-3">Additional Startup Questions (Optional)</h6>
                <tags-input
                  class="my-3"
                  placeHolder="Add Question"
                  v-model="model.additionalQuestions"
                ></tags-input>
                <label
                  >Questions listed here will be required when a new startup is
                  added to the program</label
                >
              </div>
              <div class="col-12 col-md-6">
                <ValidationProvider
                  name="PerformanceDataPoints"
                  v-slot="{ errors }"
                >
                  <h6 class="mt-3">
                    Performance Data Points (Requires 3-10 Unique values)
                  </h6>
                  <tags-input
                    class="mt-3"
                    placeHolder="Add Data Point"
                    v-model="model.performanceDataPoints"
                  ></tags-input>
                  <label class="my-2 text-danger">{{ errors[0] }}</label>
                  <br />
                  <label
                    >Performance data points will be used to measure each
                    startup (1-5)</label
                  >
                </ValidationProvider>
              </div>
              <div class="col-12 col-md-6">
                <ValidationProvider
                  rules="required"
                  name="StartupSectors"
                  v-slot="{ errors }"
                >
                  <h6 class="mt-3">Startup Sectors</h6>
                  <tags-input
                    class="mt-3"
                    placeHolder="Add Sector"
                    v-model="model.sectors"
                  ></tags-input>
                  <label class="my-2 text-danger">{{ errors[0] }}</label>
                  <br />
                  <label>Startups will only use sectors provided here</label>
                </ValidationProvider>
              </div>
              <div class="col-12 col-md-6">
                <ValidationProvider
                  rules="required"
                  name="StartupStages"
                  v-slot="{ errors }"
                >
                  <h6 class="mt-3">Startup Stages</h6>
                  <tags-input
                    class="mt-3"
                    placeHolder="Add Stage"
                    v-model="model.stages"
                  ></tags-input>
                  <label class="my-2 text-danger">{{ errors[0] }}</label>
                  <br />
                  <label>Startups will only use stages provided here</label>
                </ValidationProvider>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </card>
    </div>
  </div>
</template>
<script>
import { TagsInput } from "src/components/index";
import { mapGetters } from "vuex";
export default {
  components: {
    TagsInput,
  },
  created() {
    this.model.sectors = this.defaultSectors();
    this.model.stages = this.defaultStages();
  },
  data() {
    return {
      model: {
        additionalQuestions: [],
        performanceDataPoints: [],
        sectors: [],
        stages: [],
      },
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    },
    ...mapGetters({
      defaultStages: "program/defaultStages",
      defaultSectors: "program/defaultSectors",
    })
  },
};
</script>
<style></style>
