<template>
  <div>
    <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
    <div v-if="!isLoading">
      <div class="d-flex flex-column-reverse flex-xl-row">
        <div class="col-xl-7 col-12 startup-col-container mt-4 mt-xl-0 ">
          <div class="animate__animated animate__fadeIn position-relative" v-if="!isLoadingProgram">
            <div class="startups-supported">
              <b>Startups supported</b>
              <p>{{ startupNo }}</p>
            </div>
            <card class="mar-small" type="chart">
              <template slot="header">
                <div class="row">
                  <div class="col-12 col-xl-8">
                    <h5 class="white mb-0 pb-0">
                      Average performance data
                    </h5>
                    <h2 class="white mb-0 pb-0">
                      {{ selectedProgramName }}
                      <router-link v-bind:to="{
                        name: 'Program-Detail',
                        params: { program_id: selectedProgram },
                      }">
                        <h5 class="d-flex white align-items-center mb-0 mt-2">
                          View program detail <i class="ml-2 tim-icons icon-link-72"></i>
                        </h5>
                      </router-link>
                    </h2>
                  </div>
                </div>
                <hr />
              </template>
              <div class="chart-area">
                <line-chart v-if="performanceChart.chartData != null" style="height: 100%" ref="bigChart"
                  :chart-data="performanceChart.chartData" :gradient-colors="performanceChart.gradientColors"
                  :gradient-stops="performanceChart.gradientStops" :extra-options="performanceChart.extraOptions">
                </line-chart>
                <h3 v-else><span class="alert alert-dark"> Performance data unavailable</span></h3>
              </div>
            </card>
          </div>
          <i v-if="isLoadingProgram" class="fas fa-spinner fa-spin"></i>
        </div>
        <div class="col-xl-5 col-12 mb-4 mb-xl-0 ml-0 ml-xl-4 animate__animated animate__fadeIn  ">
          <h1 class="card-title">Dashboard</h1>
          <p class="card-description">
            {{ this.date | moment("MMM, DD YYYY") }}
          </p>
          <p>
            Selected program
          </p>
          <el-select class="select-default program-selector" size="large" placeholder="List of programs"
            v-model="selectedProgram" value-key="selectedProgramName" @change="programDataFetch()">
            <el-option v-for="option in programSelectOptions" class="select-default" :value="option.id"
              :label="option.name" :key="option.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row animate__animated animate__fadeInUp  animate__delay-1s" v-if="!isLoadingProgram">
        <div class="col-lg-3 col-md-6 ">
          <stats-card :title="startupNo" sub-title="Startups" type="primary" icon="lni lni-rocket-5">
          </stats-card>
        </div>
        <div class="col-lg-3 col-md-6  
" v-if="timeLeft != null">
          <stats-card :title="timeLeft" sub-title="Time left" type="primary" icon="lni lni-calendar-days">
          </stats-card>
        </div>
        <div class="col-lg-3 col-md-6 
">
          <stats-card :title="averagePerformance" sub-title="Average performance" type="primary" icon="lni lni-trend-up-1">
          </stats-card>
        </div>
        <div class="col-lg-3 col-md-6 ">
          <stats-card :title="jobsNo" sub-title="Jobs Created" type="primary" icon="lni lni-briefcase-1">
          </stats-card>
        </div>
      </div>

      <hr />
      <card class="card-plain" body-classes="table-full-width">
        <p class="card-description">Number of supported startups in all programs</p>
        <h1 class="card-title">{{ this.allStartupsNo || "0" }}</h1>
        <el-table :data="programsList">
          <el-table-column min-width="100" label="Program name" property="name"></el-table-column>
          <el-table-column min-width="100" label="# Startups" property="supported"></el-table-column>
          <el-table-column min-width="100" label="# Incubation managers">
            <div slot-scope="{ row }">
              <span v-if="row.incubationManagers != undefined">
                {{ row.incubationManagers.length }}
              </span>
              <span v-else>
                0
              </span>
            </div>
          </el-table-column>
        </el-table>
      </card>

    </div>
  </div>
</template>
<script>
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import config from "@/config";
import { Option, Select } from "element-ui";
import StatsCard from "src/components/Cards/StatsCard";
import { mapActions, mapGetters } from 'vuex';

let bigChartLabels = [
  "Business Model",
  "Finance",
  "Product Dev",
  "Marketing",
  "Team Building",
  "Fundraising",
];
let bigChartDatasetOptions = {
  fill: true,
  borderColor: "#fff",
  borderWidth: 3,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: "#fff",
  backgroundColor: "#fff3",
  pointBorderColor: "rgb(66, 71, 66)",
  pointHoverBackgroundColor: "rgb(66, 71, 66)",
  pointBorderWidth: 3,
  pointHoverRadius: 10,
  pointHoverBorderWidth: 3,
  pointRadius: 7,
};
export default {
  name: "AdminDashboard",
  components: {
  
    [Option.name]: Option,
    [Select.name]: Select,
    LineChart,
     StatsCard,
  },
  data() {
    return {
      defaultPagination: 1,
      isLoading: true,
      isLoadingProgram: false,
      selectedProgram: null,
      startupNo: null,
      selectedProgramName: null,
      allStartupsNo: null,
      timeLeft: null,
      jobsNo: null,
      programStartups: null,
      programDetails: null,
      averagePerformance: null,
      date: new Date(),
      programSelectOptions: [],
      programsList: [],
      performanceChart: {
        activeIndex: 0,
        chartData: {
          labels: [],
          datasets: [
            {
              ...bigChartDatasetOptions,
              data: [],
            },
          ],
        },
        extraOptions: chartConfigs.iceChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      },
    };
  },
  created() {
    //Programs
    this.fetchPrograms();
  },
  computed: {
    ...mapGetters({
      type: "auth/type",
      getAllPrograms: "program/getAllPrograms",
      getAllStartupsForProgram: "startup/getAllStartupsForProgram",
    }),
    performanceChartCategories() {
      return [
        { name: "Initial", icon: "fas fa-calendar-minus" },
        { name: "Now", icon: "fas fa-calendar-plus" },
      ];
    },
  },
  methods: {
    ...mapActions({
      getCurrentUser: "user/getLoggedInUserData",
      userLogOut: "auth/userLogOut",
      getProgram: "program/getProgram",
      cummulateStartupEvaluations: "startup/cummulateStartupEvaluations",
      calculateStartupPerformance: "startup/calculateStartupPerformance",
      meanStartupEvaluations: "startup/meanStartupEvaluations"
    }),
    async fetchPrograms() {
      let type = this.type;
      if (type != 1) {
        const currentUser = await this.getCurrentUser();
        if (currentUser) {
          if (
            currentUser.programs == null ||
            currentUser.programs.length == 0
          ) {
            this.programsList = [];
          } else {
            this.programsList = await this.getAllPrograms(currentUser.programs);
          }
        } else {
          await this.userLogOut();
          this.$router.push("/login");
        }
      } else {
        this.programsList = await this.getAllPrograms();
      }
      this.programSelectOptions = this.programsList.map((program) => { return { name: program.name, id: program.id } });
      this.selectProgram(this.programsList[0])
      this.allStartupsNo = this.programsList.map(program => program.supported).reduce((a, b) => a + b);
      this.isLoading = false;
    },
    selectProgram(program) {
      if (program == null) {
        return;
      }
      this.selectedProgram = program.id;
      this.selectedProgramName = program.name;
      this.programDataFetch();
    },
    async calculateProgramData() {
      const performances = [];
      const startups = this.programStartups;
      const evaluations = [];
      //
      this.performanceChart.chartData = null;
      this.averagePerformance = 0;
      if (this.programDetails != undefined && this.programDetails.performanceDataPoints != undefined) {
        const dataPoints = this.programDetails.performanceDataPoints;
        this.jobsNo = startups.map(startup => {
          return parseInt(startup.employees.female) + parseInt(startup.employees.male);
        }).reduce((a, b) => a + b, 0);
        for (let index = 0; index < startups.length; index++) {
          const startup = startups[index];
          if (startup.performanceDataPointsWithValues != undefined) {
            // Average score of all performance evaluations.
            let data = [];
            data = startup.performanceDataPointsWithValues;
            await this.cummulateStartupEvaluations({ evaluations: data, labels: dataPoints }).then(async (value) => {
              evaluations.push(value);
              await this.calculateStartupPerformance({ evaluationData: value.data }).then((performance) => {
                performances.push(performance);
              })
            });
          }
        }
        const mean = await this.meanStartupEvaluations({ evaluations: evaluations, labels: dataPoints });
        // 
        if (performances.length > 0) {
          this.averagePerformance = `${Math.floor(performances.reduce((a, b) => a + b) / performances.length)}%`;
        }
        if (mean) {
          let chartData = {
            labels: this.createDataPoints(
              mean.data
            ).labels,
            datasets: [
              {
                ...bigChartDatasetOptions,
                data: this.createDataPoints(
                  mean.data
                ).data,
              },
            ],
          };
          this.performanceChart.evaluatorEmail = mean.evaluatorEmail;
          this.performanceChart.evaluatorType = mean.evaluatorType;
          this.performanceChart.chartData = chartData;
          this.performanceChart.timeStamp = mean.timestamp;
          this.performanceChart.chartData.labels = dataPoints;
        }
      }

    },
    createDataPoints(_data) {
      let labels = [];
      let data = [];
      for (const [key, value] of Object.entries(_data).sort()) {
        labels.push(key);
        data.push(value);
      }
      return {
        data: data,
        labels: labels,
      };
    },
    async programDataFetch() {
      if (this.selectedProgram != null) {
        this.isLoadingProgram = true;
        await this.getProgram(this.selectedProgram).then(async (program) => {
          this.programDetails = program;
          this.selectedProgram = program.id;
          this.selectedProgramName = program.name;
          this.startupNo = program.supported;
          let timeLeft =
            program.end.toDate().getTime() - new Date().getTime();
          if (timeLeft > 0) {
            this.timeLeft = `${Math.ceil(
              timeLeft / (1000 * 60 * 60 * 24)
            )} Days`;
          } else {
            this.timeLeft = null;
          }
          // this.jobsNo = program.jobs;
          // this.timeLeft = program.initialCap;
          // let total =
          //   Number(program.initialCap) +
          //   Number(program.profits) +
          //   Number(program.assets);
          // let initialCapPer = (Number(program.initialCap) * 100) / total;
          // let profitsPer = (Number(program.profits) * 100) / total;
          // let assetsPer = (Number(program.assets) * 100) / total;
          // (this.programsList = [
          //   {
          //     name: "Funds",
          //     amount: program.initialCap,
          //     percentage: initialCapPer.toFixed(2) + "%",
          //   },
          //   {
          //     name: "Profits",
          //     amount: program.profits,
          //     percentage: profitsPer.toFixed(2) + "%",
          //   },
          //   {
          //     name: "Assets",
          //     amount: program.assets,
          //     percentage: assetsPer.toFixed(2) + "%",
          //   },
          // ]),
          // (this.allStartupsNo = program.valuation);
        });
        this.programStartups = await this.getAllStartupsForProgram(this.selectedProgram);
        this.calculateProgramData();
        this.isLoadingProgram = false;
      }
      // return 100;
    },
    // TODO: staving off chart data doing
    initBigChart(index) {
      let xx = this.$refs;
      let yy = this.performanceChart;
      this.performanceChart.activeIndex = index;
      // if (this.selectedProgram != null) {
      //   this.getProgram("programs")
      //     .then(doc => {
      //       if (index == 0) {
      //         let data = [
      //           doc.businessModel / doc.startups,
      //           doc.finance / doc.startups,
      //           doc.productDev / doc.startups,
      //           doc.marketing / doc.startups,
      //           doc.teamBuilding / doc.startups,
      //           doc.fundraising / doc.startups,
      //         ];
      //         let chartData = {
      //           datasets: [
      //             {
      //               ...bigChartDatasetOptions,
      //               data: data,
      //             },
      //           ],
      //           labels: bigChartLabels,
      //         };
      //         xx.bigChart.updateGradients(chartData);
      //         yy.chartData = chartData;
      //       } else if (index == 1) {
      //         let data = [
      //           doc.businessModelNow / doc.startups,
      //           doc.financeNow / doc.startups,
      //           doc.productDevNow / doc.startups,
      //           doc.marketingNow / doc.startups,
      //           doc.teamBuildingNow / doc.startups,
      //           doc.fundraisingNow / doc.startups,
      //         ];
      //         let chartData = {
      //           datasets: [
      //             {
      //               ...bigChartDatasetOptions,
      //               data: data,
      //             },
      //           ],
      //           labels: bigChartLabels,
      //         };
      //         xx.bigChart.updateGradients(chartData);
      //         yy.chartData = chartData;
      //       }
      //     }).catch(errMessage => {
      //       Swal.fire({
      //         icon: 'error',
      //         title: 'Oops...',
      //         text: errMessage
      //       })
      //     });
      // }
    },
  },
};
</script>
<style lang="scss">
.startup-col-container {
  @media only screen and (max-width: 768px) {
    padding: 0px !important;
    margin-bottom: 20px;
  }

  .card-title {
    margin-bottom: 0px;
  }

  .card-chart {
    background-color: rgb(242, 71, 66) !important;
    margin-left: -50px;
    margin-top: -50px;
    padding: 50px;
    border-radius: 0px;
    border-bottom-right-radius: 100px;

    @media only screen and (max-width: 768px) {
      margin: initial;
      margin-top: -20px;
      padding: initial;
      padding-right: 50px;
      padding-top: 100px;
      border-bottom-right-radius: 0px;
    }

    &::before,
    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 60%;
      height: 60%;
      background-image: linear-gradient(rgba(0, 0, 0, 0.2),
          rgba(255, 255, 255, 0.3));
      opacity: 0.5;
      border-radius: 3rem;
      transform: rotate(-45deg);
      left: -43%;
      top: 70%;
    }

    &::after {
      top: -50%;
      left: 50%;
      transform: rotate(45deg);
    }

    .card-header {
      z-index: 1;

      hr {
        border-top: 1px solid rgba(255, 255, 255, 0.5);
      }

      .white {
        color: white;
      }

      h1,
      h2 {
        font-weight: bold !important;
        // margin-bottom: 35px !important;
      }

      .dropdown {
        position: relative !important;
        margin-left: 15px !important;
        width: 150px !important;
        top: 0px !important;
      }

      .el-select {
        .el-input {
          input {
            border-color: white !important;
            color: white;
          }

          .el-input__icon {
            color: white;
          }
        }
      }

      .chart-data-toggle {
        .btn-simple:not(.active) {
          background-color: transparent !important;
          background-image: none !important;
          border-color: white !important;
          color: white;
        }

        .active {
          background-image: none !important;
          background-color: white !important;
          color: rgb(242, 71, 66) !important;
        }
      }
    }

    .card-body {
      z-index: 1;
    }
  }

  .dropdown-top {
    padding: 4px !important;
  }

  .mar-bot-small {
    margin-bottom: 10px;
  }

  .startups-supported {
    position: absolute;
    width: 7rem;
    height: 7rem;
    top: calc(50% - 3.5rem);
    right: 0rem;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;
    padding: 10px;
    border-radius: 50%;
    text-align: center;
    font-size: 2.25rem;
    line-height: 2.25rem;
    box-shadow: 0 0.5rem 3.5rem 0 rgba(242, 71, 66, 0.4);

    @media only screen and (max-width: 768px) {
      top: 28%;
    }

    @media only screen and (max-width: 575px) {
      top: 12%;
    }

    b {
      opacity: 0.5;
      text-transform: uppercase;
      font-size: 0.625rem;
      line-height: 0.625rem;
      margin-bottom: 5px;
    }

    p {
      font-weight: bold;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      width: calc(100% + 2rem);
      height: calc(100% + 2rem);
      border-radius: 50%;
      border: dotted 0.375rem rgba(247, 162, 151, 0.5);
    }
  }
}

.card-stats .info-icon.icon-primary {
  background-image: none;
}
</style>
