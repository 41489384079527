var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',{staticClass:"stacked-form",attrs:{"title":"Stacked Form"}},[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v("Add Milestone")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',[_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('label',[_vm._v("Name (Required)")]),_c('base-input',{staticClass:"mb-2",class:[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ],attrs:{"required":"","type":"text","placeholder":"Enter milestone name","error":errors[0]},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('label',[_vm._v("End Date (Required)")]),_c('el-date-picker',{staticClass:"mb-2",class:[
                    { 'has-success': passed },
                    { 'has-danger': failed },
                  ],attrs:{"required":"","type":"date","label":"End Date","placeholder":"End Date","error":errors[0]},model:{value:(_vm.model.workdays),callback:function ($$v) {_vm.$set(_vm.model, "workdays", $$v)},expression:"model.workdays"}})]}}],null,true)}),_c('label',[_vm._v("Tags")]),_c('tags-input',{staticClass:"mb-2",attrs:{"label":"Tags"},model:{value:(_vm.model.tags),callback:function ($$v) {_vm.$set(_vm.model, "tags", $$v)},expression:"model.tags"}}),_c('label',[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.description),expression:"model.description"}],staticClass:"form-text form-control mb-2",attrs:{"placeholder":"Enter milestone description","label":"Description","name":"","id":"","cols":"30","rows":"5"},domProps:{"value":(_vm.model.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.model, "description", $event.target.value)}}}),_c('div',{staticClass:"d-flex"},[_c('base-switch',{model:{value:(_vm.model.isFileUpload),callback:function ($$v) {_vm.$set(_vm.model, "isFileUpload", $$v)},expression:"model.isFileUpload"}},[_c('i',{staticClass:"tim-icons icon-check-2",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"tim-icons icon-simple-remove",attrs:{"slot":"off"},slot:"off"})]),_c('label',{staticClass:"ml-2"},[_vm._v("Has file upload")])],1),_c('base-button',{staticClass:"mt-4",attrs:{"id":"submit","native-type":"submit","type":"danger"}},[_vm._v("Create Milestone")])],1)])])]}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }