<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Add Milestone</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <div>
              <div class="col-md-8">
                <ValidationProvider
                  name="Name"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
                >
                  <label>Name (Required)</label>
                  <base-input
                    required
                    v-model="model.name"
                    type="text"
                    class="mb-2"
                    placeholder="Enter milestone name"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </base-input>
                </ValidationProvider>
                <ValidationProvider
                  name="End Date"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                  <label>End Date (Required)</label>
                  <el-date-picker
                    required
                    type="date"
                    class="mb-2"
                    label="End Date"
                    placeholder="End Date"
                    v-model="model.workdays"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </el-date-picker>
                </ValidationProvider>
                <label>Tags</label>
                <tags-input
                  class="mb-2"
                  label="Tags"
                  v-model="model.tags"
                ></tags-input>
                <label>Description</label>
                <textarea
                  class="form-text form-control mb-2"
                  v-model="model.description"
                  placeholder="Enter milestone description"
                  label="Description"
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                ></textarea>
                <div class="d-flex">
                  <base-switch v-model="model.isFileUpload" >
                    <i class="tim-icons icon-check-2" slot="on"></i>
                    <i class="tim-icons icon-simple-remove" slot="off"></i>
                  </base-switch>
                  <label class="ml-2">Has file upload</label>
                </div>
                <base-button
                  class="mt-4"
                  id="submit"
                  native-type="submit"
                  type="danger"
                  >Create Milestone</base-button
                >
              </div>
            </div>
          </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import { DatePicker } from "element-ui";
import { BaseSwitch, TagsInput } from "src/components/index";
import swal from "sweetalert2";
import { extend } from "vee-validate";
import { email, min, numeric, required } from "vee-validate/dist/rules";
import { mapActions } from "vuex";
extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);
export default {
  components: {
    TagsInput,
    BaseSwitch,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      loading: false,
      model: {
        name: null,
        description: null,
        workdays: null,
        description: null,
        tags: [],
        isFileUpload: false,
      },
    };
  },
  methods: {
    ...mapActions({
      addMilestone: "program/addProgramMilestone",
    }),
    async submit() {
      try {
        this.loading = true;
        const milestone = {
          name: this.model.name,
          description: this.model.description,
          tags: this.model.tags,
          workdays: this.model.workdays,
          program: this.$route.params.program_id,
          time: Number(new Date().valueOf()),
          isFileUpload: this.model.isFileUpload,
        };
        await this.addMilestone({
          programId: this.$route.params.program_id,
          milestone: milestone,
        });
        swal.fire({
          title: `Success!`,
          text: `You have succesfully added the milestone ${this.model.name}`,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "success",
        });
        this.loading = false;
        this.$router.push(`/programs/detail/${this.$route.params.program_id}`);
      } catch (error) {
        this.loading = false;
        swal.fire({
          title: `Adding Milestone Failed!`,
          text: error.message,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "error",
        });
      }
    },
  },
};
</script>
<style lang="scss">
.bootstrap-switch {
  .bootstrap-switch-label {
    background: white !important;
  }
  .bootstrap-switch-handle-on {
    background-color: limegreen;
    visibility: visible;
    opacity: 1;
  }
  .bootstrap-switch-handle-off {
    background-color: red;
    visibility: visible;
    opacity: 1;
  }
}
</style>
