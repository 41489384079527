<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="General Program Information">
        <ValidationObserver ref="form">
          <form @submit.prevent="validate">
            <div class="row">
              <div class="col-sm-5">
                <ValidationProvider
                  name="Name"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    required
                    label="Program Name"
                    v-model="model.name"
                    type="text"
                    placeholder="Enter program name"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </base-input>
                </ValidationProvider>           
                <label for="Start Date">Program Description</label>
                <textarea
                  class="form-text form-control"
                  v-model="model.description"
                  placeholder="Enter program description"
                  label="Description"
                  name="description"
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <div class="col-sm-5">
                <ValidationProvider
                  name="Start Date"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                <label for="Start Date">Program Start Date</label>
                  <el-date-picker
                    required
                    type="date"
                    placeholder="Start Date"
                    v-model="model.start"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </el-date-picker>
                </ValidationProvider>
                <ValidationProvider
                  name="End Date"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                <label for="End Date" class="mt-4">Program End Date</label>
                  <el-date-picker
                    required
                    type="date"
                    label="End Date"
                    placeholder="End Date"
                    v-model="model.end"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </el-date-picker>
                </ValidationProvider>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import { DatePicker, Option, Select } from "element-ui";
import { extend } from "vee-validate";
import { email, min, numeric, required } from "vee-validate/dist/rules";
extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      model: {
        name: null,
        start: null,
        end: null,
        description: null,
      },
    };
  },
  methods: {
    validate() {
      console.table(this.model);
      return this.$refs.form.validate().then(res => {
        this.model.name = this.model.name.replace(/[^\w\s]/gi, "").trim();
        this.$emit("on-validated",res, this.model);
        return res;
      });
    }
  },
};
</script>
<style>
</style>
